import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">
      
<path d="M12853 18768 c-13 -20 -15 -2965 -3 -2989 10 -19 21 -19 278 -17
l267 3 5 515 c6 486 7 518 26 571 31 85 77 137 153 173 58 27 79 31 168 34 92
3 108 1 156 -21 60 -27 98 -63 130 -122 22 -39 22 -48 27 -587 3 -368 8 -551
16 -558 7 -7 97 -9 275 -8 l264 3 0 610 c0 603 -1 611 -23 690 -74 261 -244
438 -472 491 -35 8 -110 15 -165 15 -211 0 -370 -73 -489 -223 -42 -54 -51
-58 -60 -25 -3 12 -6 339 -6 728 0 535 -3 708 -12 717 -18 18 -524 17 -535 0z"/>
<path d="M20456 18764 c-14 -14 -16 -164 -16 -1498 0 -1245 2 -1485 14 -1495
9 -8 91 -11 277 -9 l264 3 5 250 c3 165 9 257 17 270 6 11 39 47 72 79 54 53
62 58 77 45 9 -8 105 -147 212 -309 107 -162 204 -305 215 -317 20 -23 22 -23
328 -23 236 0 308 3 311 13 3 6 -145 238 -328 515 -184 276 -334 511 -334 521
0 10 36 60 81 112 45 52 104 121 132 154 27 33 120 140 206 238 175 201 179
206 160 218 -16 10 -556 11 -581 1 -9 -4 -61 -59 -115 -123 -326 -386 -444
-519 -460 -519 -12 0 -14 8 -8 43 8 51 18 1792 10 1824 l-5 23 -259 0 c-223 0
-262 -2 -275 -16z"/>
<path d="M10412 18588 c-9 -9 -12 -335 -12 -1407 0 -768 4 -1402 8 -1409 7 -9
71 -12 288 -10 l279 3 5 312 c3 188 9 316 15 321 6 6 100 12 210 15 232 6 374
27 533 81 188 64 330 152 449 279 115 123 183 249 230 428 25 96 27 119 28
299 0 203 -4 232 -52 384 -124 396 -488 652 -1003 706 -143 15 -963 13 -978
-2z m949 -513 c261 -39 419 -164 468 -370 34 -148 31 -299 -9 -436 -37 -123
-121 -218 -245 -275 -110 -51 -187 -65 -389 -71 -166 -5 -180 -4 -193 13 -10
14 -13 126 -13 567 0 359 4 555 10 568 10 17 22 19 143 19 73 0 175 -7 228
-15z"/>
<path d="M15630 17559 c-99 -16 -181 -40 -257 -75 -114 -52 -263 -165 -263
-200 0 -20 268 -308 290 -312 10 -2 47 18 84 43 79 56 154 83 255 95 207 23
340 -62 357 -229 l7 -58 -274 -6 c-356 -8 -475 -28 -622 -104 -90 -48 -165
-129 -202 -219 -32 -82 -44 -227 -27 -337 32 -204 180 -353 410 -411 87 -22
313 -22 390 0 114 33 227 111 300 208 12 16 26 26 30 22 4 -4 6 -46 4 -93 -4
-136 -33 -124 276 -121 l267 3 0 590 c0 670 1 661 -82 834 -95 200 -289 335
-530 370 -84 13 -334 13 -413 0z m464 -1085 c36 -36 6 -164 -53 -223 -64 -64
-134 -85 -276 -85 -109 0 -124 2 -164 24 -64 36 -84 68 -84 134 0 60 17 95 59
123 51 33 115 42 311 42 161 1 194 -1 207 -15z"/>
<path d="M18013 17551 c-88 -25 -165 -75 -239 -155 -35 -38 -64 -72 -64 -77 0
-14 -30 -10 -31 4 -1 6 0 51 1 100 1 57 -2 93 -10 102 -10 13 -55 15 -271 15
-245 0 -259 -1 -269 -19 -7 -13 -10 -306 -10 -879 0 -716 2 -861 14 -871 9 -8
91 -11 277 -9 l264 3 6 530 c5 544 7 570 47 634 58 95 134 131 275 131 155 0
228 -50 263 -181 11 -43 14 -155 14 -571 0 -284 3 -523 6 -532 5 -14 38 -16
268 -16 190 0 265 3 274 12 9 9 12 140 12 527 0 577 2 594 67 671 61 71 100
85 239 85 117 0 122 -1 170 -30 35 -22 57 -46 79 -85 l30 -55 5 -550 c3 -302
9 -556 13 -562 13 -20 520 -18 536 1 9 11 11 157 8 598 -4 630 -6 651 -62 791
-83 208 -250 351 -459 393 -121 25 -317 16 -417 -19 -107 -36 -190 -88 -279
-173 -43 -41 -83 -74 -91 -74 -8 1 -35 32 -61 70 -72 104 -173 172 -296 199
-79 17 -236 13 -309 -8z"/>
<path d="M23065 17555 c-143 -25 -230 -56 -335 -120 -72 -43 -170 -131 -170
-151 0 -20 268 -308 291 -312 9 -2 39 12 66 31 106 76 207 109 333 109 108 1
183 -26 234 -82 38 -42 70 -130 64 -177 l-3 -28 -260 -6 c-278 -7 -374 -17
-500 -55 -191 -56 -324 -188 -355 -353 -13 -69 -13 -203 0 -272 27 -144 135
-280 273 -344 107 -49 188 -65 339 -65 204 0 309 40 436 168 40 41 76 72 79
69 3 -4 5 -46 4 -95 -1 -66 2 -92 12 -101 10 -8 88 -11 273 -9 l259 3 3 535
c3 558 -2 645 -40 775 -67 231 -245 402 -485 467 -102 27 -396 35 -518 13z
m479 -1081 c36 -36 6 -164 -53 -223 -64 -64 -134 -85 -276 -85 -109 0 -124 2
-164 24 -86 48 -110 120 -69 200 43 84 93 97 360 99 157 1 189 -1 202 -15z"/>
<path d="M24361 17526 c-9 -11 -5 -29 20 -82 17 -38 48 -109 69 -159 35 -83
183 -421 366 -837 175 -396 224 -510 224 -519 0 -12 -93 -232 -150 -354 -22
-47 -40 -88 -40 -91 0 -2 -29 -68 -64 -147 -134 -297 -186 -419 -186 -436 0
-11 10 -21 23 -25 37 -10 474 -7 502 4 14 5 30 21 35 34 10 26 474 1092 595
1366 50 113 271 619 327 750 28 63 84 191 125 284 45 103 73 179 71 195 l-3
26 -245 3 c-135 1 -255 0 -268 -3 -28 -7 -34 -20 -192 -415 -125 -312 -154
-397 -190 -550 -23 -96 -37 -127 -51 -113 -4 4 -23 69 -42 143 -38 142 -91
297 -231 674 -47 127 -86 231 -86 232 0 2 -7 10 -16 18 -13 14 -54 16 -298 16
-231 0 -286 -3 -295 -14z"/>
</g>
</svg>

        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}